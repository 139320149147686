/***** Homepage *****/

body.page-home {
  .header {
    .logo {
      top: 144px;
      margin-left: 15px;
      transition: none;
      @include max($lg) {
        top: 144px;
        padding: 30px 0 12px;
      }
      @include max($sm) {
        top: 0 !important;
        padding: 12px 0;
      }
      img {
        @include max($lg) {
          width: 195px;
          height: 90px;
        }
        @include max($sm) {
          width: auto;
          height: 35px;
        }
      }
    }
  }
  &.fixed-header,
  &.fixed-header-const {
    .header {
      .logo {
        transition: all 0.2s;
      }
    }
  }
}

.section-home-hero {  
  text-align: center;
  height: 900px;
  position: relative;
  padding: 211px 20px 40px;  
  @include max($huge) {
    height: 750px;
  }
  @include max($xxl) {
    height: 600px;
  }
  @include max($lg) {
    height: auto;
  }
  @include max($md) {
    padding-bottom: 80vw;
  }
  @include max($sm) {
    padding: 30px 20px 80vw;
    background-size: 100% auto;
  }
  h1 {    
    font-size: 52px;
    font-weight: 500;
    margin: 0 0 15px;
    line-height: 1.33;
    position: relative;
    z-index: 1;
    @include max($lg) {
      font-size: 36px;
    }
    @include max($sm) {
      font-size: 30px;
    }
  }
  p {
    font-size: 20px;
    margin-bottom: 60px;
    letter-spacing: -0.02em;
    position: relative;
    z-index: 1;
    @include max($lg) {
      font-size: 18px;
      margin-bottom: 30px;
    }
    @include max($sm) {
      font-size: 16px;
    }
  }
  .btns {
    justify-content: center;
    position: relative;
    z-index: 1;
    .btn {}
  }
  .homebg {
    position: absolute;
    z-index: 0;
    bottom: 0;
    &.homebg-1 {
      left: 20px;
      width: 40%;
      @include max($md) {
        display: none;
      }
    }
    &.homebg-2 {
      right: 20px;
      width: 40%;
      @include max($md) {
        width: auto;
        left: 0;
      }
    }
  }
}

.section-services {
  background: #F7F7F7;
  padding: 59px 0 87px;
  @include max($lg) {
    padding: 40px 0 40px;
  }
  h2 {
    margin-bottom: 50px;
    @include max($lg) {
      margin-bottom: 30px;
    }
  }
  .service-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -16px;
    @include max($lg) {
      margin: 0 -10px;
    }
    @include max($sm) {
      display: block;
      margin: 0;
    }
    .card {
      width: 20%;
      flex: 0 0 20%;
      text-align: center;
      padding: 0 16px;
      @include max($lg) {
        width: 33.3333%;
        flex: 0 0 33.3333%;
        padding: 0 10px 50px;
      }
      @include max($sm) {
        width: 100%;
        padding: 0 0 40px;
      }
      .card-img {
        box-shadow: 0 4px 12px rgba(#000, .16);
        border-radius: 4px;
        background: #fff;
        max-width: 233px;
        margin: 0 auto 23px;
        line-height: 0;
        @include max($sm) {
          margin: 0 auto 16px;
        }
        a {}
        img {}
      }
      .card-title {
        color: $grey5;
        font-size: 19px;
        line-height: 24px;
        font-weight: 500;
        min-height: 48px;
        margin-bottom: 16px;
        @include max($lg) {
          min-height: 0;
        }
        @include max($sm) {
          // margin: 0;
        }
      }
      .btns {
        justify-content: center;
        // @include max($sm) {
        //   display: none;
        // }
        .btn {
          
        }
      }
    }
  }
}

.section-testimonials {
  padding: 110px 0 100px;
  @include max($lg) {
    padding: 50px 0;
  }
  h2 {
    margin-bottom: 45px;
  }

  .testimonials-swiper-container {
    overflow: visible;
    padding-bottom: 80px;
    @include max($lg) {
      padding-bottom: 40px;
      overflow: hidden;
    }
    .swiper-wrapper {
      // align-items: stretch;
    }
    .swiper-slide { 
      height: auto;
      display: flex;
      align-items: stretch;
      @include max($lg) {
        width: 80%;
      }
      .card {        
        width: 100%;
        background: #fff;
        box-shadow: 0 18px 20px rgba(#000, .16);
        border-radius: 8px;
        padding: 32px 70px 32px 44px;
        display: flex;
        @include max($lg) {
          padding: 20px 50px 20px 20px;
        }
        @include max($md) {
          display: block;
          padding: 20px 50px;
        }
        @include max($sm) {
          padding: 20px;
          box-shadow: 0 8px 10px rgba(#000, .16);
        }
        .card-img {
          width: 118px;
          flex: 0 0 118px;
          line-height: 0;
          @include max($lg) {
            width: 90px;
            flex: 0 0 90px;
          }
          @include max($md) {
            width: 118px;
            margin: 0 auto 10px;
          }
          img {
            border-radius: 50%;
          }
        }
        .card-testimonial {
          margin-left: 52px;
          flex: 1;
          min-width: 0;
          @include max($lg) {
            margin-left: 30px;
          }
          @include max($md) {
            margin: 0 0 10px;
            text-align: center;
          }
          .card-text {
            line-height: 1.8;
            position: relative;
            // width: 300px;
            margin-bottom: 5px;
            .card-text-i {}
            .icon {
              position: absolute;
              @include max($lg) {
                width: 30px;
                height: 20px;
              }
              &.icon-quotes-t {
                top: -15px;
                left: -42px;
                @include max($lg) {
                  left: -30px;
                }
                @include max($sm) {
                  left: -10px;
                  top: -30px;
                }
              }              
              &.icon-quotes-b {
                bottom: -15px;
                right: -37px;
                @include max($lg) {
                  right: -30px;
                }
                @include max($sm) {
                  display: none;
                }
              }
            }
          }
          .card-author {
            font-weight: bold;
          }
        }
      }
    }
    .swiper-pagination {
      bottom: 0;
      line-height: 0;
      .swiper-pagination-bullet {
        background: #c5ccd3;
        opacity: 1;
        margin: 0 3px;
        &.swiper-pagination-bullet-active {
          background: #98a0a8;
        }
      }
    }
  }
}

.section-instruments {
  padding: 70px 0 124px;
  @include max($xxl) {
    padding: 60px 0 80px;
  }
  @include max($lg) {
    padding: 30px 0 70px;
  }
  @include max($sm) {
    padding: 30px 0;
  }
  .container {
    max-width: 1720px;
  }
  h2 {
    margin: 0 0 77px;
    @include max($xxl) {
      margin: 0 0 60px;
    }
    @include max($lg) {
      margin: 0 0 30px;
    }
  }
  .instruments-i {
    display: flex;
    justify-content: space-between;
    position: relative;
    @include max($lg) {
      display: block;
    }
  }
  .instruments-l {
    padding: 0 40px;
    width: 50%;
    flex: 0 0 50%;
    @include max($huge) {
      padding: 0;
    }
    @include max($xxl) {
      width: 60%;
      flex: 0 0 60%;
    }
    @include max($lg) {
      width: 100%;
    }
    .instrument-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -22px 16px;
      @include max($huge) {
        margin: 0 -10px 16px;
      }
      @include max($xxxs) {
        display: block;
        margin: 0;
      }
      .card-w {
        width: 33.3333%;
        flex: 0 0 33.3333%;
        padding: 0 22px 35px;
        @include max($huge) {
          padding: 0 10px 20px;
        }
        @include max($sm) {
          width: 50%;
          flex: 0 0 50%;
        }
        @include max($xxxs) {
          padding: 0 0 20px;
          width: 100%;
        }
      }
      .card {
        width: 100%;
        height: 273px;
        padding: 30px 20px 15px;
        border: 1px solid $greye;
        box-shadow: 0 3px 6px rgba(#000, .16);
        border-radius: 8px;
        background: #fff;
        display: flex;
        flex-direction: column;
        
        .card-img {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 160px;
          line-height: 0;
          img {}
        }
        .card-title {
          margin-top: auto;
          text-align: center;
          color: $grey8;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.2;
        }
      }
    }
    .btns {
      justify-content: center;
      .btn {}
    }
  }
  
  .instruments-img {
    flex: 0 0 50%;
    width: 50%;
    padding: 55px 0 0 5%;
    @include max($xxl) {
      flex: 0 0 40%;
      width: 40%;
    }
    @include max($lg) {
      display: none;
    }
    img {}
  }
}

.section-platform {
  padding: 64px 0;
  @include max($huge) {
    padding: 40px 0;
  }
  .container {
    max-width: 1520px;
  }
  h2 {
    font-size: 32px;
    margin-bottom: 17px;
    text-transform: uppercase;
    @include max($lg) {
      font-size: 27px;
      margin-bottom: 10px;
    }
    @include max($sm) {
      font-size: 24px;
    }
  }
  .subtitle {
    font-family: $font-heading;
    font-size: 32px;
    text-align: center;
    margin: 0 0 63px;
    @include max($lg) {
      font-size: 24px;
      margin-bottom: 40px;
    }
    @include max($sm) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .platform-cols {
    display: flex;
    margin: 0 -70px 10px;
    @include max($huge) {
      margin: 0 -30px 10px;
    }
    @include max($lg) {
      display: block;
      margin: 0;
    }
    .platform-col {
      width: 50%;
      flex: 0 0 50%;
      padding: 0 70px;
      @include max($huge) {
        padding: 0 30px;
      }
      @include max($lg) {
        width: 100%;
        padding: 0;
      }
    }
    .card {
      display: flex;
      align-items: flex-start;
      padding: 0 0 60px;
      min-height: 170px;
      @include max($lg) {
        min-height: 0;
        padding: 0 0 40px;
      }
      @include max($sm) {
        padding: 0 0 30px;
      }
      .card-img {
        width: 110px;
        flex: 0 0 110px;
        min-height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include max($sm) {
          width: 80px;
          flex: 0 0 80px;
          min-height: 50px;
        }
        img {}
      }
      .card-body {
        margin-left: 25px;
        @include max($sm) {
          margin-left: 20px;
        }
        flex: 1;
        .card-title {
          font-weight: bold;
          display: flex;
          align-items: center;
          .icon-w {
            line-height: 0;
            margin-left: 12px;
          }
        }
        .card-text {}
      }
    }
  }

  .btns {
    justify-content: center;
    .btn {}
  }
}