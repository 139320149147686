/***** Trader *****/

.section-hero {
  padding: 70px 0 0;
  @include max($lg) {
    padding: 30px 0 0;
  }
  @include max($xxs) {
    padding: 0;
  }
  .container {
    max-width: 1826px;
  }
  .hero-i {
    display: flex;
    justify-content: space-between;
    @include max($lg) {
      display: block;
    }
  }
  .hero-content {
    padding: 0 30px 70px;
    width: 50%;
    flex: 0 0 50%;
    @include max($huge) {
      align-self: center;
    }
    @include max($xxl) {
      padding: 0 0 30px;
    }
    @include max($lg) {
      width: 100%;
      padding: 0 0 40px;
    }
    .hero-text {
      font-size: 32px;
      font-weight: 500;
      font-family: $font-heading;
      max-width: 700px;
      @include max($huge) {
        font-size: 24px;
        max-width: 100%;
      }
      @include max($sm) {
        font-size: 20px;
      }
      &.hero-text-down {
        padding-top: 120px;
        @include max($huge) {
          padding: 0;
        }
      }
      p {
        margin: 0 0 1.5em;
        @include max($huge) {
          margin: 0 0 1em;
        }
      }
    }
    .btns {
      margin-top: 2em;
      .btn {}
    }
  }
  .hero-img {
    width: 50%;
    flex: 0 0 50%;
    line-height: 0;
    padding: 50px 0 3px 30px;
    align-self: flex-end;
    @include max($lg) {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding: 0 0 5px;
    }
    img {
    }
  }
}

.section-proposal {
  background: $greyf;
  padding: 50px 0 80px;
  @include max($xxl) {
    padding: 50px 0;
  }
  .container {
    max-width: 1500px;
  }
  h2 {
    margin: 0 0 63px;
    @include max($xxl) {
      margin: 0 0 40px;
    }
  }

  .proposal-list {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -29px 0;
    @include max($huge) {
      margin: 0 -15px;
    }
    @include max($xxl) {
      max-width: 700px;
      margin: 0 auto;
    }
    @include max($md) {
      max-width: inherit;
      margin: 0 -10px;
    }
    @include max($sm) {
      display: block;
      margin: 0;
    }
    .card-w {
      padding: 0 29px 58px;
      display: flex;
      align-items: stretch;
      @include max($huge) {
        padding: 0 15px 30px;
        flex: 0 0 25%;
        width: 25%;
      }
      @include max($xxl) {
        flex: 0 0 330px;
        width: 330px;
      }
      @include max($md) {
        flex: 0 0 50%;
        width: 50%;
        padding: 0 10px 20px;
      }
      @include max($sm) {
        width: 100%;
        max-width: 330px;
        margin: 0 auto;
        padding: 0 0 20px;
      }
    }
    .card {
      width: 301px;
      min-height: 435px;
      padding: 30px 20px 100px;
      text-align: center;
      position: relative;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 20px 24px rgba(#000, .2);
      @include max($huge) {
        width: 100%;
      }
      @include max($lg) {
        box-shadow: 0 6px 20px rgba(#000, .16);
      }
      .card-img {
        width: 100%;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        a {
          line-height: 0;
        }
        .icon {}
      }
      .card-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .card-text {
        font-weight: 500;
        max-width: 210px;
        margin: 0 auto;
      }
      
      .card-table {
        display: flex;
        justify-content: space-between;
        &.card-table-centered {
          justify-content: center;
        }
        .col {
          &:not(:last-child) {
            padding-right: 3px;
          }
          > div {
            font-weight: normal;
            padding: 1px 0;
            min-height: 26px;
            b {
              font-weight: 500;
            }
          }
          &.col-text-right {
            text-align: right;
          }
          &.col-text-left {
            text-align: left;
          }
          &.col-rounds {
            width: 35px;
            flex: 0 0 35px;
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .btns {
        padding: 24px 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        justify-content: center;
        display: block;
        .card-notice {
          font-size: 10px;
          color: rgba($grey4, .6);
          margin: 0 auto 10px;
          max-width: 130px;
          a {
            color: rgba($grey4, .6);
            &:hover {
              color: $primary;
            }
          }
        }
        .btn {
          width: 164px;
          max-width: 100%;
        }
      }
    }
  }
}

.section-bservices {
  padding: 140px 0 100px;
  @include max($xxl) {
    padding: 60px 0;
  }
  @include max($sm) {
    padding: 40px 0;
  }
  .container {}
  h2 {
    margin: 0 0 5px;
    text-transform: uppercase;
    &.no-subtitle {
      margin-bottom: 60px;
      @include max($lg) {
        margin-bottom: 30px;
      }
    }
  }
  .subtitle {
    font-size: 24px;
    text-align: center;
    margin: 0 0 40px;
  }

  .bservice-list {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -29px;
    @include max($huge) {
      margin: 0 -15px;
    }
    @include max($lg) {
      margin: 0 auto;
      display: block;
      max-width: 370px;
    }
    .card-w {
      padding: 0 29px;
      display: flex;
      align-items: stretch;
      @include max($huge) {
        padding: 0 15px 30px;
      }
      @include max($xxl) {
        width: 33.3333%;
        flex: 0 0 33.3333%;
      }
      @include max($lg) {
        width: 100%;
        padding: 0 0 20px;
      }
    }
    .card {
      width: 334px;
      padding: 20px 20px 120px;
      text-align: center;
      position: relative;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 6px 20px rgba(#000, .16);
      @include max($xxl) {
        width: 100%;
      }
      .card-img {
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        .icon {
          fill: $primary;
        }
      }
      .card-title {
        font-weight: 500;
        letter-spacing: -0.02em;
      }
      .card-text {
        letter-spacing: -0.02em;
        > div {
          padding: 7px 0;
        }
      }
      .btns {
        padding: 24px 20px 38px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        justify-content: center;
        .btn {
          box-shadow: 0 18px 20px rgba($primary, .3);
        }
      }
    }
  }
}

.section-meeting {
  padding: 150px 0;
  @include max($huge) {
    padding: 70px 0 55px;
  }
  @include max($xxl) {
    padding: 30px 0 40px;
  }
  @include max($sm) {
  }
  text-align: center;
  .container {}
  .photo {
    margin-bottom: 7px;
    img {
      border-radius: 50%;
    }
  }
  .name {
    margin-bottom: 7px;
    b {}
  }
  .text {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.02em;
    margin-bottom: 10px;
    line-height: 1.2;
    @include max($sm) {
      font-size: 20px;
    }
  }
  .integration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 25px;
    .icon {
      fill: $grey8;
      margin-right: 5px;
    }
    span {
      a {
        text-decoration: underline;
        &:hover {

        }
      }
    }
  }
  .btns {
    justify-content: center;
    .btn {
      padding: 8px 30px;
    }
  }
}