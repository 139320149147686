/**** Form elements *****/

.form-group {
  margin-bottom: 20px;
}
.form-control {
  border: 1px solid $grey4;
  background: #fff;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 10px 20px;
  line-height: 20px;
  font-size: 14px;
}

.form-group.form-group-q {
  position: relative;
  height: 48px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $grey8;
    border-radius: 4px;  
  }
  label {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
    font-size: 20px;
    letter-spacing: -0.02em;
    transition: all 0.2s;
    color: $grey8;
  }
  .form-control {
    border: none;
    font-size: 20px;
    letter-spacing: -0.02em;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px 0 0 10px;
    background: none;
  }
  &.focus {
    &:after {
      border: 2px solid $primary;
    }
    label {
      top: 4px;
      font-size: 14px;
    }
  }
  &.valid {
    label {
      top: 4px;
      font-size: 14px;
    }
  }
  
}

// .select-container {
//   position: relative;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   background: #fff;
//   max-width: 100%;
//   overflow: hidden;
//   height: 40px;
//   display: flex;
//   align-items: stretch;
//   transition: all 0.3s;
//   &.focused {
//     border-color: #aaa;
//   }
//   @include max($xxxs) {
//     width: 100%;
    
//   }
//   &:after {
//     content: "";
//     display: block;
//     position: absolute;
//     right: 10px;
//     top: 13px;
//     width: 17px;
//     height: 10px;
//     background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173.8 98.6"><path class="a" d="M96.8,98.6,183.8,0H10Z" transform="translate(-10 0)"/></svg>') 50% 50% no-repeat;
//     background-size: contain;
//   }  
//   select {
//     appearance: none;
//     border: none;
//     background: none;
//     font-size: 16px;
//     font-weight: normal;
//     padding: 8px 40px 8px 12px;
//     height: 38px;
//     line-height: 22px;
//     width: 100%;
//     @include max($xxxs) {
//       height: 30px;      
//     }
//   }
// }


.form-check {
  margin-bottom: 10px;
}
.custom-checkbox {  
  position: relative;
  @include max($lg) {
    display: flex;
    padding: 0 0 0 50px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: flex-start;
    min-height: 50px;
  }
  .icon-w {
    width: 115px;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
    @include max($lg) {
      width: 50px;
      height: 50px;
      flex: 0 0 50px;
      margin: 0 10px 0 0;
    }
  }
  input[type="checkbox"] { 
    position: absolute; 
    opacity: 0; 
    z-index: -1; 
    
  }
  .img {
    text-align: center;
    line-height: 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    max-width: 200px;
    margin: 0 auto 23px;
    img {          
      margin: 0 auto;
    }
  }
  .text {
    position: relative;
    margin: 0 0 15px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.02em;
    display: block;
    @include max($xxl) {
      font-size: 20px;
    }
    @include max($lg) {
      text-align: left;
      margin: 0;
    }
    @include max($sm) {
      font-size: 16px;
    }
    &.text-sm {
      font-size: 16px;
      min-height: 72px;
    }
  }
  .chk {
    border: 2px solid #888;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    position: relative;
    @include max($lg) {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
    }
    &:after {
      content: "";
      display: none;
      position: absolute;
      top: 5px;
      left: 2px;
      width: 21px;
      height: 16px;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 575.6 415.7"><path d="M223.9,438.8,466.4,196.2a64,64,0,0,1,90.5,90.4L269.1,574.4a64,64,0,0,1-90.5,0L18.7,414.5a64,64,0,0,1,90.5-90.4Z" style="fill: %231D97FF;" transform="translate(0 -177.5)"/></svg>');
      background-size: contain;
    }
  }
  
  &.checked {
    .icon-w {
      .icon.icon-anim {
        .a {
          fill: $primary;
        }
        &.icon-advanced-systems {
          .a {
            fill: #e3e3e3;
          }
          .d {
            fill: $primary;
          }
        }
      }
    }
    .chk {
      border-color: $primary;
      &:after {
        display: block;
      }
    }
  }
}

.form-radio {
  margin-bottom: 35px;
}
.custom-radio {
  input[type="radio"] { 
    position: absolute; 
    opacity: 0; 
    z-index: -1; 
    + span {
      position: relative;
      padding: 3px 0 3px 37px;
      line-height: 22px;
      font-weight: bold;
      letter-spacing: -0.02em;
      display: block;
      &:before {
        content: '';
        display: block;
        border: 1px solid #D0D0D0;
        border-radius: 50%;
        background: #E0E0E0;
        width: 28px;
        height: 28px;
        position: absolute;
        left: 0;
        top: 0;
        box-sizing: border-box;
      }
      &:after {
        content: "";
        display: none;
        position: absolute;
        left: 6px;
        top: 6px;
        width: 16px;
        height: 16px;
        background: $primary;
        border-radius: 50%;
        z-index: 1;
      }
      
    }
    &:checked {
      + span {
        &:before {
          
        }
        &:after {
          display: block;
        }
      }
    }
    &:focus {
      + span {
        &:before {
        }
      }
    }
    &:not(:disabled) {
      span {
        &:hover {
          &:before {
          }
        }
      }
    }
  }
}

.custom-radio-lg {
  position: relative;
  @include max($lg) {
    display: flex;
    padding: 0 0 0 50px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: flex-start;
    min-height: 50px;
  }
  .icon-w {
    width: 115px;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
    @include max($lg) {
      width: 50px;
      height: 50px;
      flex: 0 0 50px;
      margin: 0 10px 0 0;
    }
  }
  input[type="radio"] { 
    position: absolute; 
    opacity: 0; 
    z-index: -1; 
    
  }
  .img {
    text-align: center;
    line-height: 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    max-width: 200px;
    margin: 0 auto 23px;
    border: 2px solid transparent;
    @include max($lg) {
      margin: 0 10px 0 0;
      width: 100px;
      flex: 0 0 100px;
    }
    @include max($sm) {
      width: 70px;
      flex: 0 0 70px;
    }
    img {          
      margin: 0 auto;
      position: relative;
    }
  }
  .text {
    position: relative;
    margin: 0 0 15px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.02em;
    display: block;
    @include max($xxl) {
      font-size: 20px;
    }
    @include max($lg) {
      text-align: left;
      margin: 0;
    }
    &.text-sm {
      font-size: 16px;
      min-height: 72px;
      @include max($lg) {
        min-height: 0;
      }
    }
  }
  .chk {
    border: 2px solid #888;
    border-radius: 50%;
    // background: #E0E0E0;
    width: 35px;
    height: 35px;
    margin: 0 auto;
    position: relative;
    @include max($lg) {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      content: "";
      display: none;
      position: absolute;
      left: 5px;
      top: 5px;
      width: 21px;
      height: 21px;
      background: $primary;
      border-radius: 50%;
      z-index: 1;
      box-sizing: border-box;
    }
  }
  
  &.checked {
    
    .icon-w {
      .icon.icon-anim {
        .a {
          fill: $primary;
        }
        &.icon-advanced-systems {
          .a {
            fill: #e3e3e3;
          }
          .d {
            fill: $primary;
          }
        }
      }
    }
    .img {
      border-color: $primary;
    }
    .chk {
      border-color: $primary;
      &:after {
        display: block;
      }
    }
  }
}
