/* Color */
$primary: #1D97FF;
$primaryhover: #41a5fd;
$grey4: #434343;
$grey5: #585858;
$grey6: #6A6A6A;
$grey7: #707070;
$grey8: #888;
$greyd: #D4D4D4;
$greye: #e2e2e2;
$greyf: #f7f7f7;

$font-heading: 'IBM Plex Serif', serif;
$font-basic: 'IBM Plex Sans', sans-serif;

/* Size */
$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
