/***** Footer *****/

.footer {
  background: #202b34;
  padding: 32px 0 90px;
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
  @include max($lg) {
    padding: 30px 0;
  }
  a {
    color: #fff;
    &:hover {
      color: #fff;
      opacity: 0.8;
    }
  }
  .container {
    max-width: 1056px;
  }
  .footer-i {
    display: flex;
    position: relative;
    @include max($sm) {
      display: block;
    }
  }
  .footer-logo {
    width: 180px;
    flex: 0 0 180px;
    margin-left: -180px;
    @include max($xxxl) {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    @include max($sm) {
      width: 100%;
      text-align: center;
      position: relative;
      top: auto;
      left: auto;
      margin-bottom: 20px;
    }
    img {
      width: 119px;
      height: 55px;
    }
  }
  .footer-content {
    flex: 1;
    padding-top: 10px;
    @include max($lg) {
      max-width: 600px;
      margin: 0 auto;
      padding: 0;
    }
    h2 {
      font-family: $font-basic;
      font-weight: bold;
      font-size: 24px;
      margin: 0 0 50px;
      text-transform: uppercase;
    }
    .footer-cols {
      display: flex;
      @include max($lg) {
        display: block;
      }
    }
    .footer-nav {
      padding: 15px 0 90px;
      flex: 0 0 540px;
      width: 540px;
      @include max($lg) {
        width: 100%;
        padding: 0 0 30px;
      }
      @include max($xs) {
        padding: 0;
      }
      p {
        font-weight: bold;
        margin-bottom: 40px;
      }

      .footer-nav-cols {
        display: flex;
        margin: 0 -20px;
        @include max($xs) {
          display: block;
          margin: 0;
        }
      }
      .footer-nav-col {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 20px;
        @include max($xs) {
          width: 100%;
          padding: 0;
        }
      }
      .footer-nav-block {
        margin: 0 0 35px;
        min-height: 100px;
        @include max($xs) {
          min-height: 0;
        }
      }
      .footer-nav-h {
        font-weight: bold;
      }
      .footer-nav-b {
        a {}
        ul {
          padding: 0 0 0 3px;
          margin: 3px 0;
          li {
            list-style: none;
            a {}
          }
        }
      }
    }

    .footer-contact {
      width: 311px;
      flex: 0 0 311px;
      margin-left: auto;
      @include max($lg) {
        width: 100%;
        margin: 0 auto 40px;
      }
      p {}
      .input-group {
        margin-bottom: 11px;
        .form-control {
          background: rgba(#fff, .1);
          border: none;
          padding: 11px 10px;
          color: #fff;
          @include placeholder {
            opacity: 0.34;
          }
          &:focus {
            @include placeholder {
              opacity: 0.24;
            }
          }
        }
        textarea.form-control {
          resize: none;
          height: 110px;
        }
      }
      .btns {
        justify-content: flex-end;
        .btn {
          box-shadow: none;
          min-width: 157px;
        }
      }
    }

    .footer-text {
      text-align: center;
      color: rgba(#fff, .5);
      p {
      }
    }
  }
}