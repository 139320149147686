/***** Header *****/

.header {
  padding: 17px 22px 17px 10px;
  background: #fff;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 74px;
  @include max($lg) {
    padding: 10px 15px 10px 10px;
    height: 60px;
  }
  .header-i {
    
  }
  .header-nav {
    display: flex;
    align-items: center;
    @include max($sm) {
      display: none;
      z-index: 10;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background: #fff;
      padding: 10px 0 0;
    }
    &.active {
      @include max($sm) {
        display: block;
        box-shadow: 0 20px 40px 30px #fff
      }
    }
  }
  a.link {
    color: $grey6;
    padding: 10px 15px;
    line-height: 20px;
    display: block;
    font-weight: 500;
    @include max($lg) {
      padding: 5px 10px;
    }
    &:hover {
      color: $primaryhover;
    }
  }
  .header-nav-l {
    a {
      @include max($sm) {
        display: block;
        padding: 15px 20px;
        border-bottom: 1px solid $greye;
      }
      &:not(:last-child) {
        margin-left: 5px;
        @include max($sm) {
          margin: 0;
        }
      }
    }
  }
  .header-nav-r {
    margin-left: auto;
    display: flex;
    align-items: center;
    @include max($sm) {
      display: block;
      margin: 0;
    }
    a {
      @include max($sm) {
        display: block;
        padding: 15px 20px;
        border-bottom: 1px solid $greye;
      }
      &:not(:last-child) {
        margin-right: 5px;
        @include max($sm) {
          margin: 0;
        }
      }
    }
    .btn {
      @include max($sm) {
        background: none;
        color: $grey6;
        border-radius: 0;
        padding: 15px 20px;
        text-align: left;
        font-size: 16px;
        line-height: 20px;
        height: auto;
        box-shadow: none;
        border-bottom: 1px solid $greye;
      }
      &:hover {
        @include max($sm) {
          color: $primaryhover;
        }
      }
    }
  }
  .logo {
    position: fixed;
    top: 0;
    left: 50%;
    padding: 30px 0 12px;
    transform: translateX(-50%);
    transition: all 0.2s;
    @include max($lg) {
      top: 0;
      padding: 12px 0;
    }
    a {}
    img {
      width: 195px;
      height: 90px;
      transition: all 0.4s;
      @include max($lg) {
        width: auto;
        height: 35px;
      }
    }    
  }

  .toggler {
    display: none;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 10px;
    right: 10px;
    margin-left: auto;
    cursor: pointer;
    padding: 13px 4px;
    
    @include max($sm) {
      display: block;
    }
    
    span {
      position: absolute;
      left: 5px;
      right: 5px;
      display: block;
      height: 2px;
      background: $grey4;
      transition: all 0.2s;
      &:before,
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: $grey4;
        position: absolute;
        left: 0;
        right: 0;
        transition: all 0.2s;
      }
      &:before {
        top: 6px;
      }
      &:after {
        top: 12px;
      }
    }
    &:hover {
      span {
        background: $primary;
        &:before,
        &:after {
          background: $primary;
        }
      }
    }
  }
  
  
}

.header-v2 {
  padding: 10px 50px 10px 70px;
  height: 60px;
  background: #fff;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);
  @include max($lg) {
    padding: 10px 15px 10px 10px;
  }
  .header-i {
    display: flex;
    align-items: center;
    
  }
  .logo {
    margin-right: 120px;
    @include max($xxl) {
      margin-right: 40px;
    }
    img {
      height: 35px;
    }
  }
  .header-nav {
    flex: 1;
    display: flex;
    @include max($md) {
      display: none;
      z-index: 10;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background: #fff;
      padding: 10px 0 0;
    }
    &.active {
      @include max($md) {
        display: block;
        
      }
    }
    .header-nav-l {
      display: flex;
      align-items: center;
      @include max($sm) {
        display: block;
        margin: 0;
      }
      a {
        @include max($md) {
          display: block;
          padding: 15px 20px;
          border-bottom: 1px solid $greye;
        }
        &:not(:last-child) {
          margin-right: 30px;
          @include max($md) {
            margin: 0;
          }
        }
      }
    }
    .header-nav-r {
      margin-left: auto;
      display: flex;
      align-items: center;
      @include max($sm) {
        display: block;
        margin: 0;
      }
      a {
        @include max($md) {
          display: block;
          padding: 15px 20px;
          border-bottom: 1px solid $greye;
        }
        &:not(:last-child) {
          margin-right: 30px;
          @include max($md) {
            margin: 0;
          }
        }
      }
    }
    
  }
  .toggler {
    display: none;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 10px;
    right: 10px;
    margin-left: auto;
    cursor: pointer;
    padding: 13px 4px;
    
    @include max($md) {
      display: block;
    }
    
    span {
      position: absolute;
      left: 5px;
      right: 5px;
      display: block;
      height: 2px;
      background: $grey4;
      transition: all 0.2s;
      &:before,
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: $grey4;
        position: absolute;
        left: 0;
        right: 0;
        transition: all 0.2s;
      }
      &:before {
        top: 6px;
      }
      &:after {
        top: 12px;
      }
    }
    &:hover {
      span {
        background: $primary;
        &:before,
        &:after {
          background: $primary;
        }
      }
    }
  }
}



body.fixed-header,
body.fixed-header-const {
  .header {
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    .logo {
      top: 0 !important;
      padding: 12px 0;
      img {
        width: auto;
        height: 50px;
        @include max($lg) {
          height: 35px;
        }
      }
    }
  }
  &.page-home {
    .header {
      .logo {
        top: 0 !important;
        padding: 12px 0;
        img {
          @include max($lg) {
            height: 35px;
          }
        }
      }
    }
  }
}
