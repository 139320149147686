/***** Returning Client *****/

body.page-returning {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .header {
    flex: 0 0 74px;
    @include max($lg) {
      flex: 0 0 60px;
    }
  }
  .main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;
  }
}

.section-returning {
  padding: 30px;
  @include max($lg) {
    padding: 20px 20px;
  }
  .returning-t {
    margin-bottom: 100px;
    @include max($lg) {
      margin-bottom: 50px;
    }
    h1 {
      font-size: 32px;
      font-weight: normal;
      margin: 0 0 13px;
      text-align: center;
    }
    .btns {
      justify-content: center;
      .btn {
        box-shadow: 0 18px 20px rgba(#00C0F5, .3);
      }
    }
  }

  .returning-info {
    h2 {
      font-size: 24px;
      font-weight: normal;
      text-align: center;
      margin: 0 0 30px;
    }
    .services-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -15px;
      @include max($xxs) {
        margin: 0 -10px;
      }
      .card-w {
        padding: 0 15px 30px;
        @include max($xxs) {
          width: 50%;
          flex: 0 0 50%;
          padding: 0 10px 30px;
        }
      }
      .card {
        width: 145px;
        height: 184px;
        padding: 30px 12px 12px;
        border: 1px solid $greye;
        box-shadow: 0 3px 6px rgba(#000, .16);
        border-radius: 8px;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;
        @include max($xxs) {
          width: 100%;
        }
        
        .card-img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 100px;
          margin: 0 auto 10px;
          line-height: 0;
          img {
            max-height: 100%;
          }
        }
        .card-title {
          margin-top: auto;
          text-align: center;
          color: $grey8;
          font-size: 13px;
          font-weight: bold;
          line-height: 1.2;
        }
      }
      .btns {
        justify-content: center;
        .btn {
          min-width: 100px;
        }
      }
    }
  }
}