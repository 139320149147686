/***** Simple elements, atoms *****/

.section-grey {
  background: #f7f7f7;
}

.icon {
  &.icon-anim {
    .a, .c {
      fill:#e3e3e3;
      transition: all 0.2s;
    }
    .b {
      fill:#4a4a4a;
    }
    .d {
      fill: #fff;
      transition: all 0.2s;
    }
    // &.icon-advanced-systems {
    //   .d {
    //     transition: all 0.2s;
    //   }
    // }
    &.icon-diversity-of-products {
      .d {
        transition: all 0.2s;
      }
    }
  }
  &.icon-round-primary {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $primary;
  }
}
a:hover {
  .icon.icon-anim {
    .a {
      fill: $primary;
    }
    &.icon-advanced-systems {
      .a {
        fill: #e3e3e3;
      }
      .d {
        fill: $primary;
      }
    }
    // &.icon-diversity-of-products {
    //   .a {
    //     fill: #4a4a4a;
    //   }
    //   .b {
    //     fill: $primary;
    //   }
    // }
  }
}