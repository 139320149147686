/***** Questionaire *****/

body.page-questionaire {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .header {
    flex: 0 0 74px;
    @include max($lg) {
      flex: 0 0 60px;
    }
  }
  .main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center; 
  }
}

.section-questionaire {
  padding: 100px 30px 20px;
  @include max($lg) {
    padding: 20px 20px;
  }
  .question {
    margin: 0 auto;
    .question-img {
      
      width: 116px;
      height: 116px;
      line-height: 0;
      margin: 0 auto 40px;
      @include max($lg) {
        margin-bottom: 20px;
      }
      @include max($sm) {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
      }
      img {
        border-radius: 50%;
      }
    }
    .question-text {
      font-size: 32px;
      text-align: center;
      font-family: $font-heading;
      max-width: 50%;
      margin: 0 auto 20px;
      @include max($huge) {
        max-width: 600px;
      }
      @include max($lg) {
        font-size: 24px;
        max-width: 100%;
      }
      @include max($sm) {
        font-size: 18px;
      }
      p {
        margin: 0 0 0.5em;
      }
    }
    .form-group {
      margin: 0 auto 23px;
      .custom-radio {}
    }
    .btns {
      justify-content: center;
      margin-top: 35px;
      @include max($lg) {
        margin-top: 10px;
      }
      .btn {
        box-shadow: 0 6px 20px $primary;
      }
    }
    .row-470 {
      max-width: 470px;
      margin: 0 auto;
      @include max($lg) {
        max-width: 100%;
      }
    }
  }

  .question-cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 50px -40px 0;
    @include max($huge) {
      margin: 40px -30px 0;
    }
    @include max($xxl) {
      margin: 30px -15px 0;
    }
    @include max($lg) {
      display: block;
      margin: 30px 0 0;
      
    }
    .question-col {
      width: 280px;
      flex: 0 0 280px;
      padding: 0 20px 50px;
      @include max($lg) {
        width: 100%;
        padding: 0;
      }
      &.custom-checkbox {
        padding-left: 50px !important;
      }
    }
    .custom-radio-lg {
      padding: 0 40px 50px;
      @include max($huge) {
        padding: 0 30px 50px;
      }
      @include max($xxl) {
        padding: 0 15px 50px;
      }
      @include max($lg) {
        padding: 0 0 0 50px;
      }
      
      span {}
      
    }
    &.question-cols-4 {
      .question-col {
        
      }
    }
    &.question-cols-5 {
      .question-col {
      }
    }
    &.question-cols-7 {
      .question-col {
      }
    }
  }

  &.section-questionaire-5 {
    .custom-radio-lg {
      .img {
        height: 100px;
        background: #f7f7f7;
        display: flex;
        align-items: center;
        justify-content: center;
        @include max($sm) {
          height: 60px;
          padding: 2px;
        }
      }
    }
  }
}