/***** Hedge Fund *****/

.section-info {
  padding: 150px 0 170px;
  @include max($xxl) {
    padding: 80px 0;
  }
  @include max($sm) {
    padding: 40px 0;
  }
  .container {
    max-width: 1700px;
  }
  h2 {
    margin: 0 0 100px;
    @include max($xxl) {
      margin: 0 0 60px;
    }
    @include max($sm) {
      margin: 0 0 40px;
    }
  }

  .info-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include max($lg) {
      display: block;
    }
    .video {
      flex: 0 0 46%;
      width: 46%;
      @include max($lg) {
        width: 100%;
        max-width: 600px;
        margin: 0 auto 40px;

      }
      a {
        position: relative;
        line-height: 0;
        display: block;
        width: 100%;
        padding-bottom: 66%;
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(#000, .2);
          z-index: 1;
          border-radius: 4px;
        }
        img.video-preview {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          border-radius: 4px;
        }
        .icon-w {
          width: 130px;
          height: 130px;
          background: #fff;
          border-radius: 50%;
          box-shadow: 0 3px 6px rgba(#000, .16);
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          @include max($sm) {
            width: 90px;
            height: 90px;
          }
          .icon {
            transition: all 0.2s;
            margin-left: 10%;
            fill: $grey4;
            @include max($sm) {
              width: 40px;
              height: 40px;
            }
          }
        }
        &:hover {
          .icon-w {
            .icon {
              fill: $primary;
            }
          }
        }
      }
      
    }
    .img {
      flex: 0 0 46%;
      width: 46%;
      line-height: 0;
      // align-self: center;
      @include max($lg) {
        width: 100%;
        max-width: 600px;
        margin: 0 auto 40px;

      }
    }
    .info {
      width: 45%;
      flex: 0 0 45%;
      @include max($lg) {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
      }
      ul.primary-list {
        margin: 0 0 1.2em;
        padding: 0;
        font-size: 32px;
        font-weight: 500;
        font-family: $font-heading;
        @include max($huge) {
          font-size: 27px;
        }
        @include max($xxl) {
          font-size: 24px;
        }
        @include max($lg) {
          font-size: 20px;
        }
        li {
          list-style: none;
          position: relative;
          margin: 0 0 0.7em;
          padding-left: 36px;
          &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 0.6em;
            left: 0;
            background: $primary;
            border-radius: 50%;
          }
        }
      }
      .btns {}
    }
  }

  &.section-info-brokerage {
    .container {
      max-width: 1900px;
    }
    .img {
      width: 51%;
      flex: 0 0 51%;
      @include max($lg) {
        width: 100%;
      }
    }
    .info {
      // width: 40%;
      // flex: 0 0 40%;
      // @include max($lg) {
      //   width: 100%;
      // }
      ul.primary-list {}
      .btns {
        .btn {
          min-width: 325px;
          @include max($sm) {
            min-width: 0;
          }
        }
      }
    }
  }
}