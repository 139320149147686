/***** Default and helpers *****/

@import "mixins";
@import "variables";


@font-face {
  font-family: 'IBM Plex Sans';
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url('../fonts/subset-IBMPlexSans.woff2') format('woff2'),
      url('../fonts/subset-IBMPlexSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url('../fonts/subset-IBMPlexSans-Medium.woff2') format('woff2'),
      url('../fonts/subset-IBMPlexSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
      url('../fonts/subset-IBMPlexSans-SemiBold.woff2') format('woff2'),
      url('../fonts/subset-IBMPlexSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url('../fonts/subset-IBMPlexSans-Bold.woff2') format('woff2'),
      url('../fonts/subset-IBMPlexSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: local('IBM Plex Serif'), local('IBMPlexSerif'),
      url('../fonts/subset-IBMPlexSerif.woff2') format('woff2'),
      url('../fonts/subset-IBMPlexSerif.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'IBM Plex Serif';
  src: local('IBM Plex Serif Medium'), local('IBMPlexSerif-Medium'),
      url('../fonts/subset-IBMPlexSerif-Medium.woff2') format('woff2'),
      url('../fonts/subset-IBMPlexSerif-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
    font-family: 'IBM Plex Serif';
    src: local('IBM Plex Serif SemiBold'), local('IBMPlexSerif-SemiBold'),
        url('../fonts/subset-IBMPlexSerif-SemiBold.woff2') format('woff2'),
        url('../fonts/subset-IBMPlexSerif-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
// @font-face {
//     font-family: 'IBM Plex Serif';
//     src: local('IBM Plex Serif Bold'), local('IBMPlexSerif-Bold'),
//         url('../fonts/subset-IBMPlexSerif-Bold.woff2') format('woff2'),
//         url('../fonts/subset-IBMPlexSerif-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }






html {
  overflow: none;
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  overflow-x: hidden;
  color: $grey4;
  font-family: $font-basic;
  font-size: 16px;
  height: 100%;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

input[type="submit"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="url"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

input,
textarea,
label {
  font-family: $font-basic;
}
a,
button,
input,
textarea,
select {
  outline: none;
}


input,
textarea {
  @include placeholder {
    opacity: 0.7;
  }
  &:focus {
    @include placeholder {
      opacity: 0.4;
    }
  }
}

textarea {
  resize: vertical;
}

table {
  border-collapse: collapse;
}

a {
  color: $grey4;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    color: $primary;
  }
}

h1,
h2,
h3,
h4 {
  font-family: $font-heading;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin: 0 0 1em;
  text-align: center;
  font-weight: 600;
}

h1 {
}
h2 {
  font-size: 32px;
  @include max($sm) {
    font-size: 27px;
  }
}
h3 {
}
h4 {
}

p {
  margin: 0 0 1em;
}

b,
strong {
}

img {
  max-width: 100%;
  height: auto;
}
